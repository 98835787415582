// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}

// .btn-light{
//   border: 1px solid darken($light, 2%);
// }

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

// .btn-dark, .btn-secondary {
//   color: $gray-200;
// }

.btn-outline-light {
  color: $gray-900;
}