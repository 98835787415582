// 
// _table.scss
// 

// .table {
//   th {
//       font-weight: $font-weight-bold;
//   }
// }

// //Table centered
// .table-centered {
//   td,th {
//       vertical-align: middle !important;
//   }
// }

// .table-nowrap {
//   th, td {
//     white-space: nowrap;
//   }
// }

// .table-bordered {
//   border: $table-border-width solid $table-border-color;

//   th,
//   td {
//     border: $table-border-width solid $table-border-color;
//   }
// }


// .table-dark>:not(:last-child)>:last-child>*{
//   border-bottom-color:  $table-dark-border-color;
// }

.table > thead > tr > th {
  border-bottom: 2px solid $table-border-color;
}


.table {
  th {
      font-weight: $table-th-font-weight;
      color: $gray-900;
  }

  .table-light {
      color: $body-color;
      border-color: $table-border-color;
      background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;
}
.table>:not(:first-child){
  border-top: (0 * $table-border-width) solid $table-group-separator-color;
}

.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}

.table-nowrap {
  th,
  td {
      white-space: nowrap;
  }
}

.table-responsive{
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($dark, .2);
    border-radius: 10px;
    border: 2px solid $white;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;  
    background-color: $white; 
  }
}